// eslint-disable-next-line import/no-anonymous-default-export
export default {
  smartContract: {
    TOKEN_ADDRESS:
      process.env.REACT_APP_TOKEN_ADDRESS ||
      "0x93e60de66553d2cf0206f2c134997884d58e8e23",
    CONTRACT_ADDRESS:
      process.env.REACT_APP_CONTRACT_ADDRESS ||
      "0x0061229Ef4e3359d0e9c6027b792CA575cDAb7D8",
    API_URL:
      process.env.REACT_APP_API_URL || "https://api-dev.televerse.me/api/v1/",
    CHAIN_NAME: process.env.REACT_APP_CHAIN_NAME || "Smart Chain - Testnet",
    CHAIN_ID: process.env.REACT_APP_CHAIN_ID || 97,
    CHAIN_RPC_URL:
      process.env.REACT_APP_CHAIN_RPC_URL ||
      "https://endpoints.omniatech.io/v1/bsc/testnet/public",
    CHAIN_CURRENCY_SYMBOL:
      process.env.REACT_APP_CHAIN_CURRENCY_SYMBOL || "bnbt",
    CHAIN_BLOCK_EXPLORER_URL:
      process.env.REACT_APP_CHAIN_BLOCK_EXPLORER_URL ||
      "https://testnet.bscscan.com",
    VESTING_ADDRESS:
      process.env.REACT_APP_VESTING_ADDRESS ||
      "0x066328dBF4FAd3C112AE22DAdE87823c90bE08C6",
    NFT_CONTRACT_PROPOSAL_ADDRESS:
      process.env.REACT_APP_NFT_CONTRACT_PROPOSAL_ADDRESS ||
      "0x6B2b2B8f33EA39b1B1e87D30De74D2d362e25ad8",
    NFT_CONTRACT_ADDRESS:
      process.env.REACT_APP_NFT_CONTRACT_ADDRESS ||
      "0x15b9898dA01524CE05Ed7BD77b7Bd0F2bC01DE20",
    PROPOSAL_ADDRESS:
      process.env.REACT_APP_PROPOSAL_ADDRESS ||
      "0x8A9D783dc9287805C266CB69d91409B19fB875B0",
    REVENUE_ADDRESS:
      process.env.REACT_APP_REVENUE_ADDRESS ||
      "0x05Babd86D2Fa83ddfF23303eA3CE84683C1448AF",
    // "0x73F77e3883e093D7272874507A9dEbBb49e69A9D",
    MARKETPLACE_ADDRESS:
      process.env.REACT_APP_MARKETPLACE_ADDRESS ||
      "0x050AAe71206E1cF348E1c43BAb6cD83090d3920B",
    NFT_MARKETPLACE_ADDRESS:
      process.env.REACT_APP_NFT_MARKETPLACE_ADDRESS ||
      "0x47DB55BeCB71d607C354629688AE1be1dFB8F0a2",
  },
};
