import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import React from "react";
import { Link } from "react-router-dom";
import "./style.css";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import NavbarConnect from "../Navbar";
import RouteGenerator from "./routes/RouteGenerator";
import NewsTicker from "../../../components/NewsTicker";

/*  This Component for displaying Header 
*  Routes file has been used to difining the links of pages
 | khaled Mofeed | 29/10/2022 */

function Header() {
  const [headerClassName, setHeaderClassName] = useState("");
  /*  This function used to handle the scroll screen and fixing the header in the top position 
  | khaled Mofeed | 29/10/2022 */
  const handleScroll = (headerClassName) => {
    const PAGE_Y_OFFSET = 100;
    if (
      headerClassName !== "menuscroll" &&
      window.pageYOffset >= PAGE_Y_OFFSET
    ) {
      setHeaderClassName("menuscroll");
    } else if (
      headerClassName === "menuscroll" &&
      window.pageYOffset < PAGE_Y_OFFSET
    ) {
      setHeaderClassName("");
    }
  };
  /*  IMPORTANT, This will cause react to update depending on change of this value
  | khaled Mofeed | 29/10/2022 */
  useEffect(() => {
    window.onscroll = () => handleScroll(headerClassName);
  }, [headerClassName]);

  return (
    <Row className={`justify-content-center header-top ${headerClassName}`}>
      <NewsTicker />
      <Col md={12} lg={10}>
        <Navbar expand="lg" sticky="top">
          <Navbar.Brand className="logoAndTitle">
            <Link to="/">
              <img
                id="id_logo"
                src={`${process.env.REACT_APP_ASSETS}/img/logo.svg`}
                alt="logo"
                className="logo d-none d-lg-block"
              />
              <img
                id="id_logoMobile"
                src={`${process.env.REACT_APP_ASSETS}/img/logo-mobile.svg`}
                alt="logo"
                className="logo-mobile d-block d-lg-none"
              />
            </Link>
            <span> . Pre Sale</span>
          </Navbar.Brand>
          {/*  This div used to handle the connect and disconnect wallet ,just view in mobile  | khaled Mofeed | 5/12/2022  */}
          <div className="d-block d-lg-none me-0 ms-auto">
            <NavbarConnect />
          </div>
          <Navbar.Toggle aria-controls="basic-navbar-nav" id="id_ToggleBtn">
            <i className="fas fa-bars"></i>
          </Navbar.Toggle>

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="">
              {/*  This element for  static menu in header | khaled Mofeed | 5/12/2022 */}
              <RouteGenerator />

              {/* <Button
                variant="primary"
                className="custom-primary d-none d-lg-block"
                id="id_DiscoverBtn"
              >
                Discover
              </Button> */}
              {/*  This div used to handle the connect and disconnect wallet ,just view in mobile  | khaled Mofeed | 5/12/2022  */}
              <div className="d-none d-lg-block">
                <NavbarConnect />
              </div>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Col>
    </Row>
  );
}

export default Header;
