/*
 * ethers library is used to interact with the smart contract
 * @see https://docs.ethers.io/v5/
 * and the JSON file is used to store the ABI of the smart contract
 * @see https://docs.ethers.io/v5/api/utils/abi/
 */
import { ethers } from "ethers";
import abi from "../../../configs/abis/presale-contract.json";
import config from "../../../configs";

const contractAddress = config.get("smartContract.CONTRACT_ADDRESS");
let provider;
if (window.ethereum) {
  provider = new ethers.providers.Web3Provider(window.ethereum);
}

// ^ Helper functions

// create a contract instance
const makeContract = async () => {
  await window.ethereum.request({ method: "eth_requestAccounts" });
  const signer = provider.getSigner();
  const contract = new ethers.Contract(contractAddress, abi, signer);
  return contract;
};

// this function is to get token decimals
const getTokenDecimals = async () => {
  const contract = await makeContract();
  const decimals = await contract.getTokenDecimals();
  return decimals;
};

// this function is used to get a single value of the smart contract promises
const singleValueFunc = async (func) => {
  const contract = await makeContract();
  const value = await contract[func]();
  return await valueParser(value);
};

// this function is used to parse a value and divide it by 10 ^ token decimals
const valueParser = async (value) => {
  const decimals = await getTokenDecimals();
  return parseInt(value) / 10 ** parseInt(decimals);
};

// this a generic function to handle the tables' data
const tableData = async (func, args) => {
  const deployedPresaleContract = await makeContract();
  const { totalList_, ...rowData } = await deployedPresaleContract[func](
    ...args
  );
  const dataArr = rowData.transactions_ || rowData.commissions_;

  const transactions = await Promise.all(
    dataArr.map(async (item, index) => ({
      id: `${index}-${item.purchaseDate}`,
      amount: parseInt(item.amount) / 10 ** 16,
      date: new Date(item.purchaseDate * 1000).toLocaleString([], {
        hour12: false,
      }),
    }))
  );
  const totalList = parseInt(totalList_);
  return { transactions, totalList };
};

// ^ Contract functions

// this function is used to get the current user's address
export const getAddress = async () => {
  const signer = provider.getSigner();
  const address = await signer.getAddress();
  return address;
};

// this function is used to get the current user's balance
export const getBalance = async () => {
  const address = await getAddress();
  const balance = await provider.getBalance(address);
  return ethers.utils.formatEther(balance);
};

// this function is used to get the presale starting date
export const getStartingDate = async () => {
  const deployedPresaleContract = await makeContract();
  return deployedPresaleContract.getStartingDate();
};

// this function is used to get the presale ending date
export const getDeadlineDate = async () => {
  const deployedPresaleContract = await makeContract();
  return deployedPresaleContract.getDeadlineDate();
};

// this function is used to get the user's total commission list
export const getUserCommissionsList = async (page, limit) => {
  const args = [page, limit];
  const data = await tableData("getUserCommissionsList", args);
  return data;
};

// this function is used to get the user's total transaction list
export const getUserPurchasedTransactionsList = async (page, limit) => {
  const args = [page, limit];
  const data = await tableData("getUserPurchasedTransactionsList", args);
  return data;
};

// this function is used to get the minimum an maximum amount of tokens that can be purchased
export const getMinMaxAllocation = async () => {
  const deployedPresaleContract = await makeContract();
  const minMaxAllocation = await deployedPresaleContract.getMinMaxAllocation();
  return {
    minAllocation: await valueParser(minMaxAllocation.minAllocation_),
    maxAllocation: await valueParser(minMaxAllocation.maxAllocation_),
  };
};

// this function is used to purchase tokens
export const purchaseTELV = async (telv, sponsor, i3) => {
  const deployedPresaleContract = await makeContract();
  await deployedPresaleContract.purchaseTELV(telv, sponsor, i3);
};

// get Casanova exchange rate
export const getTELVExchangeRate = singleValueFunc.bind(
  null,
  "getTELVExchangeRate"
);

// get whole Casanova token
export const getTotalPresaleTELVAmount = singleValueFunc.bind(
  null,
  "getTotalPresaleTELVAmount"
);

// get total amount of tokens purchased for a user
export const getUserTotalPurchasedAmount = singleValueFunc.bind(
  null,
  "getUserTotalPurchasedAmount"
);

// get the rate of recieved tokens when an affiliate sponsor is used
export const getCommissionRate = singleValueFunc.bind(
  null,
  "getCommissionRate"
);

// get total amount of tokens purchased
export const getTotalPurchasedTELVAmount = singleValueFunc.bind(
  null,
  "getTotalPurchasedTELVAmount"
);

// ^ Figure out in which phase the presale is
export const whenAreWeNow = async () => {
  const deployedPresaleContract = await makeContract();
  if (!deployedPresaleContract) return;
  const startingDate = await deployedPresaleContract.getStartingDate();
  const deadlineDate = await deployedPresaleContract.getDeadlineDate();
  const now = Math.floor(Date.now() / 1000);
  if (now < startingDate) {
    return "before";
  }
  if (now > deadlineDate) {
    return "after";
  }
  return "live";
};
